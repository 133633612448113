var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"nav",attrs:{"color":"white","height":_vm.headerHeight,"elevation":"0"}},[_c('div',{staticClass:"hidden-lg-and-up"},[_c('v-app-bar-nav-icon',{attrs:{"aria-label":"Menu latérale","name":"Menu latérale"},on:{"click":function($event){_vm.drawer = true}}})],1),_c('router-link',{attrs:{"name":"Logo ARBS","to":"/","aria-label":"Logo ARBS"}},[(this.$vuetify.breakpoint.mdAndUp)?_c('v-img',{attrs:{"lazy-src":require("@/assets/img/logo.png"),"contain":"","height":_vm.headerHeight,"max-width":"200px","src":require("@/assets/img/logo.png")}}):_c('v-img',{attrs:{"contain":"","height":_vm.headerHeight,"max-width":"80px","src":require("@/assets/img/logo-2.png")}})],1),_c('v-spacer'),(this.$route.name !== 'Login' && this.$route.name !== 'ResetPassword')?_c('div',{staticClass:"d-flex flex-column flex-sm-row"},[(this.$vuetify.breakpoint.mdAndUp && _vm.user.role !== 'GESTIONNAIRE_ARBS' &&  _vm.user.role !== 'GESTIONNAIRE_ETABLISSEMENT' && _vm.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')?_c('p',{staticClass:"  mr-sm-2 text-h5 primary--text ma-auto"},[_vm._v(" BIENVENUE ")]):_vm._e(),_c('p',{staticClass:"ma-0 mr-sm-2 text-h5 secondary--text ma-auto"},[_vm._v(" "+_vm._s(_vm.user.prenom)+" ")]),_c('p',{staticClass:"ma-0 text-h5 primary--text ma-auto"},[_vm._v(" "+_vm._s(_vm.user.nom)+" ")])]):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"hidden-md-and-down"},[(
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          (this.$store.state.user.role === 'GESTIONNAIRE_ARBS' ||
          this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')
        )?_c('ButtonNavbar',{attrs:{"height":_vm.headerHeight,"color":"primary","colorBackground":"white","text":"Import","locked":true,"icon":"mdi-database-import-outline","iconColor":"primary","isActiveNamePage":_vm.isActiveNamePage('Import')},nativeOn:{"click":function($event){_vm.$router.push({ path: "/admin/import" }).catch(function (error) {
      if (error.name !== 'NavigationDuplicated') {
      throw error;
      }
      });}}}):_vm._e(),(
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          this.$store.state.user.role === 'GESTIONNAIRE_ARBS'
        )?_c('ButtonNavbar',{attrs:{"height":_vm.headerHeight,"color":"primary","colorBackground":"white","text":"Editeur","locked":true,"icon":"mdi-progress-wrench","iconColor":"primary","isActiveNamePage":_vm.isActiveNamePage('Params')},nativeOn:{"click":function($event){_vm.$router.push({ path: "/admin/params" }).catch(function (error) {
      if (error.name !== 'NavigationDuplicated') {
      throw error;
      }
      });}}}):_vm._e(),(
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
        (this.$store.state.user.role === 'GESTIONNAIRE_ARBS' ||
        this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')
        )?_c('ButtonNavbar',{attrs:{"height":_vm.headerHeight,"color":"primary","colorBackground":"white","text":"Stock","locked":true,"icon":"mdi-dolly","iconColor":"primary","isActiveNamePage":_vm.isActiveNamePage('Stock')},nativeOn:{"click":function($event){_vm.$router.push({ path: "/admin/stock" }).catch(function (error) {
      if (error.name !== 'NavigationDuplicated') {
      throw error;
      }
      });}}}):_vm._e(),(
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          this.$store.state.user.role === 'GESTIONNAIRE_ARBS'
        )?_c('ButtonNavbar',{attrs:{"height":_vm.headerHeight,"color":"primary","colorBackground":"white","text":"Statistiques","locked":true,"icon":"mdi-chart-line","iconColor":"primary","isActiveNamePage":_vm.isActiveNamePage('Statistique')},nativeOn:{"click":function($event){_vm.$router.push({ path: "/admin/statistique" }).catch(function (error) {
      if (error.name !== 'NavigationDuplicated') {
      throw error;
      }
      });}}}):_vm._e(),(
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          this.$store.state.user.role === 'GESTIONNAIRE_ARBS'
        )?_c('ButtonNavbar',{attrs:{"height":_vm.headerHeight,"color":"primary","colorBackground":"white","text":"Historique","icon":"mdi-history","locked":true,"iconColor":"primary","isActiveNamePage":_vm.isActiveNamePage('Historique')},nativeOn:{"click":function($event){_vm.$router.push({ path: "/admin/historique" }).catch(function (error) {
      if (error.name !== 'NavigationDuplicated') {
      throw error;
      }
      });}}}):_vm._e(),(
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          (this.$store.state.user.role === 'GESTIONNAIRE_ARBS' || this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT' || this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')
        )?_c('ButtonNavbar',{attrs:{"height":_vm.headerHeight,"color":"primary","colorBackground":"white","text":"Etablissements","icon":"mdi-domain","locked":true,"iconColor":"primary","isActiveNamePage":_vm.isActiveNamePage('Etablissement')},nativeOn:{"click":function($event){return _vm.goToEtablissement()}}}):_vm._e(),(
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          (this.$store.state.user.role === 'GESTIONNAIRE_ARBS' || this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT' || this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')
        )?_c('ButtonNavbar',{attrs:{"height":_vm.headerHeight,"color":"primary","colorBackground":"white","text":"Utilisateurs","icon":"mdi-shield-account","iconColor":"primary","locked":true,"isActiveNamePage":_vm.isActiveNamePage('DashboardAdmin')},nativeOn:{"click":function($event){return _vm.goToAdmin()}}}):_vm._e(),(
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          this.$store.state.user.soutienBordas
        )?_c('ButtonNavbar',{attrs:{"height":_vm.headerHeight,"color":"primary","colorBackground":"white","text":"Accès capeezi","icon":"mdi-school","locked":false,"iconColor":"primary"},nativeOn:{"click":function($event){return _vm.goToSoutien()}}}):_vm._e(),(
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword'
        )?_c('ButtonNavbar',{attrs:{"height":_vm.headerHeight,"color":"primary","colorBackground":"white","text":"MON COMPTE","locked":true,"icon":"mdi-account","iconColor":"primary","isActiveNamePage":_vm.isActiveNamePage('MyAccount')},nativeOn:{"click":function($event){return _vm.goToMyAccount()}}}):_vm._e(),(
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          this.$store.state.user.role !== 'GESTIONNAIRE_ARBS' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'
        )?_c('ButtonNavbar',{attrs:{"height":_vm.headerHeight,"color":"primary","colorBackground":"white","text":"Accueil","icon":"mdi-home","locked":true,"iconColor":"primary","isActiveNamePage":_vm.isActiveNamePage('Dashboard')},nativeOn:{"click":function($event){return _vm.goToMyDashboard()}}}):_vm._e(),(this.$store.state.user.role !== 'GESTIONNAIRE_ARBS' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')?_c('ButtonNavbar',{attrs:{"height":_vm.headerHeight,"color":"primary","colorBackground":"white","locked":false,"text":"Assistance","icon":"mdi-comment-question","iconColor":"primary"},nativeOn:{"click":function($event){return _vm.goToHelp()}}}):_vm._e(),(
          this.$route.name !== 'Login' && this.$route.name !== 'ResetPassword'
        )?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-btn',{attrs:{"name":"déconnexion","height":_vm.headerHeight,"elevation":"0","color":"secondary","tile":"","width":"150px","dark":""},on:{"click":function($event){return _vm.logout()}}},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-icon',{attrs:{"large":"","color":"white","justify-center":""}},[_vm._v("mdi-logout ")]),_c('div',{staticClass:"text-h8 HeaderBouton font-weight-bold ma-0",class:{ 'text-decoration-underline': hover }},[_vm._v(" déconnexion ")])],1)])]}}],null,false,3144107927)}):_vm._e()],1)],1),_c('v-navigation-drawer',{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-sheet',{attrs:{"height":"50"}},[_c('v-icon',{staticStyle:{"margin":"8px"},on:{"click":function($event){_vm.drawer = false}}},[_vm._v("mdi-close ")])],1),(
        this.$route.name !== 'Login' && this.$route.name !== 'ResetPassword'
      )?_c('Button',{attrs:{"color":"secondary","text":"Déconnexion","icon":"mdi-logout","iconColor":"white"},nativeOn:{"click":function($event){return _vm.logout()}}}):_vm._e(),(this.$store.state.user.role !== 'GESTIONNAIRE_ARBS' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT'  && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')?_c('Button',{attrs:{"color":"primary","text":"Assistance","icon":"mdi-comment-question","iconColor":"white","locked":false},nativeOn:{"click":function($event){return _vm.goToHelp()}}}):_vm._e(),(
        this.$route.name !== 'Login' &&
        this.$route.name !== 'ResetPassword' 
      )?_c('Button',{attrs:{"color":_vm.isActiveNamePage('MyAccount') ?  'backgroundColor': 'white',"text":"MON COMPTE","icon":"mdi-account","locked":false,"iconColor":_vm.isActiveNamePage('MyAccount') ?  'secondary' : 'primary',"isActiveNamePage":_vm.isActiveNamePage('MyAccount')},nativeOn:{"click":function($event){return _vm.goToMyAccount()}}}):_vm._e(),(
        this.$route.name !== 'Login' &&
        this.$route.name !== 'ResetPassword' &&
        this.$store.state.user.soutienBordas
      )?_c('Button',{attrs:{"color":"white","text":"Accès capeezi","icon":"mdi-school","iconColor":"primary","locked":false},nativeOn:{"click":function($event){return _vm.goToSoutien()}}}):_vm._e(),(
        this.$route.name !== 'Login' &&
        this.$route.name !== 'ResetPassword' &&
        this.$store.state.user.role !== 'GESTIONNAIRE_ARBS' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT'  && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'
      )?_c('Button',{attrs:{"color":_vm.isActiveNamePage('Dashboard') ?  'backgroundColor': 'white',"text":"Accueil","icon":"mdi-book-open-page-variant-outline","iconColor":_vm.isActiveNamePage('Dashboard') ? 'secondary' : 'primary',"isActiveNamePage":_vm.isActiveNamePage('Dashboard'),"locked":false},nativeOn:{"click":function($event){return _vm.goToMyDashboard()}}}):_vm._e(),(
        this.$route.name !== 'Login' &&
        this.$route.name !== 'ResetPassword' &&
        this.$store.state.user.role !== 'GESTIONNAIRE_ARBS' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'
      )?_c('Button',{attrs:{"color":_vm.isActiveNamePage('Nettoyage des manuels') ?  'backgroundColor': 'white',"text":"Nettoyage","icon":"mdi-vacuum-outline","iconColor":_vm.isActiveNamePage('Nettoyage des manuels') ? 'secondary' : 'primary',"isActiveNamePage":_vm.isActiveNamePage('Nettoyage des manuels'),"locked":false},nativeOn:{"click":function($event){return _vm.goToMyCleanup()}}}):_vm._e(),(
        this.$route.name !== 'Login' &&
        this.$route.name !== 'ResetPassword' &&
        this.$store.state.user.role !== 'GESTIONNAIRE_ARBS' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'  &&
        _vm.isMobileApp()
      )?_c('Button',{attrs:{"color":_vm.isActiveNamePage('Mes documents') ?  'backgroundColor': 'white',"text":"Mes documents","icon":"mdi-file-document-multiple-outline","iconColor":_vm.isActiveNamePage('Mes documents') ? 'secondary' : 'primary',"isActiveNamePage":_vm.isActiveNamePage('Mes documents'),"locked":false},nativeOn:{"click":function($event){return _vm.goToMyDocuments()}}}):_vm._e(),(
        this.$route.name !== 'Login' &&
        this.$route.name !== 'ResetPassword' &&
        (this.$store.state.user.role === 'GESTIONNAIRE_ARBS' || this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT'|| this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')
      )?_c('Button',{attrs:{"color":_vm.isActiveNamePage('DashboardAdmin') ?  'backgroundColor': 'white',"text":"Utilisateurs","icon":"mdi-shield-account","locked":false,"iconColor":_vm.isActiveNamePage('DashboardAdmin') ? 'secondary' : 'primary',"isActiveNamePage":_vm.isActiveNamePage('DashboardAdmin')},nativeOn:{"click":function($event){return _vm.goToAdmin()}}}):_vm._e(),(
        this.$route.name !== 'Login' &&
        this.$route.name !== 'ResetPassword' &&
        (this.$store.state.user.role === 'GESTIONNAIRE_ARBS' || this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT'|| this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')
      )?_c('Button',{attrs:{"color":_vm.isActiveNamePage('Etablissement') ?  'backgroundColor': 'white',"text":"Etablissements","icon":"mdi-domain","locked":false,"iconColor":_vm.isActiveNamePage('Etablissement') ? 'secondary' : 'primary',"isActiveNamePage":_vm.isActiveNamePage('Etablissement')},nativeOn:{"click":function($event){return _vm.goToEtablissement()}}}):_vm._e(),(
        this.$route.name !== 'Login' &&
        this.$route.name !== 'ResetPassword' &&
        this.$store.state.user.role === 'GESTIONNAIRE_ARBS'
      )?_c('Button',{attrs:{"locked":false,"color":_vm.isActiveNamePage('Statistique') ?  'backgroundColor': 'white',"text":"Statistiques","icon":"mdi-chart-line","iconColor":_vm.isActiveNamePage('Statistique') ? 'secondary' : 'primary',"isActiveNamePage":_vm.isActiveNamePage('Statistique')},nativeOn:{"click":function($event){return _vm.goToStatistiques();}}}):_vm._e(),(
        this.$route.name !== 'Login' &&
        this.$route.name !== 'ResetPassword' &&
        this.$store.state.user.role === 'GESTIONNAIRE_ARBS'
      )?_c('Button',{attrs:{"color":_vm.isActiveNamePage('Historique') ?  'backgroundColor': 'white',"text":"Historique","locked":false,"icon":"mdi-history","iconColor":_vm.isActiveNamePage('Historique') ? 'secondary' : 'primary',"isActiveNamePage":_vm.isActiveNamePage('Historique')},nativeOn:{"click":function($event){return _vm.goToHistorique();}}}):_vm._e(),(
        this.$route.name !== 'Login' &&
        this.$route.name !== 'ResetPassword' &&
        (this.$store.state.user.role === 'GESTIONNAIRE_ARBS' ||
        this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')
      )?_c('Button',{attrs:{"color":_vm.isActiveNamePage('Stock') ?  'backgroundColor': 'white',"text":"Stock","icon":"mdi-dolly","locked":false,"iconColor":_vm.isActiveNamePage('Stock') ? 'secondary' : 'primary',"isActiveNamePage":_vm.isActiveNamePage('Stock')},nativeOn:{"click":function($event){return _vm.goToStock();}}}):_vm._e(),(
        this.$route.name !== 'Login' &&
        this.$route.name !== 'ResetPassword' &&
        this.$store.state.user.role === 'GESTIONNAIRE_ARBS'
      )?_c('Button',{attrs:{"color":_vm.isActiveNamePage('Params') ?  'backgroundColor': 'white',"text":"Editeur","icon":"mdi-progress-wrench","locked":false,"iconColor":_vm.isActiveNamePage('Params') ? 'secondary' : 'primary',"isActiveNamePage":_vm.isActiveNamePage('Params')},nativeOn:{"click":function($event){_vm.$router.push({ path: "/admin/params" }).catch(function (error) {
      if (error.name !== 'NavigationDuplicated') {
    throw error;
    }
    });}}}):_vm._e(),(
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          this.$store.state.user.role === 'GESTIONNAIRE_ARBS'
        )?_c('Button',{attrs:{"color":_vm.isActiveNamePage('Import') ?  'backgroundColor': 'white',"text":"Import","locked":false,"icon":"mdi-database-import-outline","isActiveNamePage":_vm.isActiveNamePage('Import'),"iconColor":_vm.isActiveNamePage('Import') ? 'secondary' : 'primary'},nativeOn:{"click":function($event){_vm.$router.push({ path: "/admin/import" }).catch(function (error) {
      if (error.name !== 'NavigationDuplicated') {
      throw error;
      }
      });}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }