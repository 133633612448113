<template>
  <v-snackbar
      v-model="snackbar.showing"
      :timeout="10000"
      :color="snackbar.color"
      justify-space-between
  >
    <v-container>
      <v-row
          justify="center"
          align="center"
      >
        <v-col cols="10">
          <span>
            {{ snackbar.text }}
          </span>

        </v-col>
        <v-col cols="2">
          <v-btn
              name="Fermer"
              right
              text
              @click="snackbar.showing = false"
          >
            <v-icon color="">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-snackbar>
</template>

<script lang="ts">
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState(["snackbar"]),
  },
};
</script>
