import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import setupInterceptors from './services/setupInterceptors';

import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.plugins.register(ChartDataLabels);

Vue.config.productionTip = false;
setupInterceptors(store, router);

new Vue({
	store,
	vuetify,
	router,
	render: (h) => h(App),
}).$mount("#app");
