<template>
  <v-hover v-slot="{ hover }">
    <v-btn
        :name="text"
        :height="height"
        elevation="0"
        :color="colorBackground"
        tile
        width="7vw"
        light
        :disabled="isActiveNamePage||clicked"
        @click="locked ?clicked=true:''"
    >
      <div>
        <v-icon
            large
            :color="iconColor"

        >{{ icon }}
        </v-icon>
        <div
            class="HeaderBouton primary--text text-h8 ma-0"
            :class="{
                'text-decoration-underline': hover,
                'secondary--text': isActiveNamePage,
              }"
        >
          {{ text }}
        </div>
      </div>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: "ButtonNavbar",
  props: [
    "color",
    "text",
    "icon",
    "iconColor",
    "height",
    "colorBackground",
    "isActiveNamePage",
    "locked"
  ],
  data: () => ({
    clicked: false,
  }),
  computed: {},
  watch: {
    isActiveNamePage: {
      handler() {
        this.clicked = false
      },
    }
  }
};
</script>
<style scoped>
.HeaderBouton {
  font-family: "bebasneue", sans-serif;
}

.theme--light.v-btn.v-btn--disabled .v-icon {
  color: #fb4d61 !important;
}
</style>
