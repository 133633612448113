import store from '../store'

class checkRole {
    checkRoleIsGestionnaire(to, from, next) {
        store.dispatch("user/updateUser")
        .then(() => {
            if (store.state.user.role === 'GESTIONNAIRE_ARBS') {
                next();
            } else if (store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT') {
                next({ name: "DashboardAdmin" });
            }else if (store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE') {
                next({ name: "DashboardAdmin" });
            } else {
                next({ name: "Dashboard" });
            }
        })    
    }
    checkRoleIsGestionnaireOrEtablissement(to, from, next) {
        store.dispatch("user/updateUser")
            .then(() => {
                if (store.state.user.role === 'GESTIONNAIRE_ARBS' || store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT' || store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE') {
                    next();
                } else {
                    next({ name: "Dashboard" });
                }
            })
    }
    checkRoleIsNotGestionnaire(to, from, next) {
        store.dispatch("user/updateUser")
        .then(() => {
            if (store.state.user.role !== 'GESTIONNAIRE_ARBS' && store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT'&& store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE') {
                next();
            } else {
                next({ name: "DashboardAdmin" });
            }
        })    
    }
}
export default new checkRole();


