import OrderService from "../services/OrderService";

const order = {
	namespaced: true,
	state: {
		devis: [],
		panier: [],
		isLoad: false,
		additionalsBooks: [],
	},
	getters: {
		classes: (state, getters) => {
			if (state.additionalsBooks.length != 0) {
				let classesAdded = state.devis.concat(getters.aditionnalClasse);
				return classesAdded;
			} else {
				return state.devis;
			}
		},
		treeviewdata: (state, getters) => {
			let id = 0;
			let newClasses = [];
			let oldetab = getters.classes;
			oldetab.forEach((classe) => {
				id++;
				let children = [];
				let name = classe.name;
				let type = classe.type;
				classe.lots.forEach((lot) => {
					let children2 = [];
					let lotid = lot.id;
					let name = lot.name;
					let type = lot.type;
					lot.livres.forEach((livre) => {
						livre.id = id;
						id++;
						children2.push(livre);
					});
					children.push({ id: lotid, name, type, children: children2 });
				});
				newClasses.push({ id, type, name, children });
			});
			return newClasses;
		},
		panier: (state, getters) => {
			let panier = [];
			if (getters.classes.length > 0) {
				getters.classes.forEach((classe) => {
					let bibliotheques = [];
					let subTotal = 0;
					let priceSubTotal = 0;
					classe.lots.forEach((lot) => {
						lot.livres.forEach((livre) => {
							if (livre.quantity > 0) {
								bibliotheques.push(livre);
								subTotal += +livre.quantity;
								priceSubTotal = parseFloat(
									+priceSubTotal + +livre.quantity * +livre.prix
								).toFixed(2);
							}
						});
					});
					if (bibliotheques.length != 0) {
						panier.push({
							codeReference: classe.name,
							subTotal,
							priceSubTotal,
							bibliotheques: bibliotheques,
						});
					}
				});
				return panier;
			} else {
				return panier;
			}
		},
		panierParEditeur: (state, getters) => {
			let panier = [];
			if (getters.classes.length > 0) {
				getters.classes.forEach((classe) => {
					classe.lots.forEach((lot) => {
						lot.livres.forEach((livre) => {
							if (livre.quantity > 0) {
								if (panier.length > 0) {
									let exist = 0;
									panier.forEach((editeur) => {
										if (
											editeur.codeReference.toUpperCase() ===
											livre.editeur.toUpperCase()
										) {
											exist = 1;
											editeur.subTotal += +livre.quantity;
											editeur.priceSubTotal = parseFloat(
												+editeur.priceSubTotal + +livre.quantity * +livre.prix
											).toFixed(2);
											editeur.bibliotheques.push(livre);
										}
									});
									if (exist == 0) {
										let bibliotheques = [livre];
										let priceSubTotal = +livre.quantity * +livre.prix;
										panier.push({
											codeReference: livre.editeur,
											subTotal: +livre.quantity,
											priceSubTotal,
											bibliotheques,
										});
									}
								} else {
									let bibliotheques = [livre];
									let priceSubTotal = +livre.quantity * +livre.prix;
									panier.push({
										codeReference: livre.editeur,
										subTotal: +livre.quantity,
										priceSubTotal,
										bibliotheques,
									});
								}
							}
						});
					});
				});
				return panier;
			} else {
				return panier;
			}
		},

		totaux: (state, getters) => {
			let totalQuantity = 0;
			let totalPrice = 0;
			getters.panier.forEach((classe) => {
				classe.bibliotheques.forEach((livre) => {
					totalQuantity += +livre.quantity;
					totalPrice = parseFloat(
						+totalPrice + +livre.quantity * +livre.prix
					).toFixed(2);
				});
			});
			return { totalQuantity, totalPrice };
		},
		aditionnalClasse(state) {
			let aditionnalClasse = {
				id: 0,
				name: "LIVRES ADDITIONNELS",
				type: "devis",
				lots: [
					{
						id: 0,
						name: "LOTS ADDITIONNELS",
						type: "lot",
						livres: state.additionalsBooks,
					},
				],
			};
			return aditionnalClasse;
		},
	},
	mutations: {
		SET_DEVIS(state, payload = []) {
			state.devis = payload.data.classes;
			state.isLoad = true;
		},
		ADD_ADDITIONAL_BOOK(state, payload) {
			let book = {
				id: payload.book.ouvrage.ean,
				name: payload.book.ouvrage.nom,
				type: "livre",
				editeur: payload.book.ouvrage.editeurCatalog.codeEditeur,
				codeEditeur: payload.book.codeEditeur,
				ean: payload.book.ouvrage.ean,
				refOffreDivalto: payload.book.refOffreDivalto,
				imgCouverture: payload.book.ouvrage.imgCouverture,
				matiere: payload.book.ouvrage.matiere,
				prix: payload.book.prixTtc,
				quantity: 0,
				canOrder: payload.book.canOrder,
			};
			state.additionalsBooks.push(book);
		},
		DELETE_ADDITIONAL_BOOK(state, payload) {
			state.additionalsBooks.splice(
				state.additionalsBooks.findIndex((v) => v.ean === payload.book.ean),
				1
			);
		},
		INCREASE_LOTS(state, payload) {
			for (let index = 0; index < payload.children.length; index++) {
				if (Number.isInteger(payload.children[index].quantity)) {
					if (payload.children[index].quantity !== -1) {
						payload.children[index].quantity += 1;
					}
				} else {
					payload.children[index].quantity = 1;
				}
			}
			this.treeviewdata = payload;
		},
		DECREASE_LOT(state, payload) {
			for (let index = 0; index < payload.children.length; index++) {
				if (payload.children[index].quantity) {
					payload.children[index].quantity += -1;
				}
			}
			this.treeviewdata = payload;
		},
		INCREASE_LOTS_10(state, payload) {
			for (let index = 0; index < payload.children.length; index++) {
				if (Number.isInteger(payload.children[index].quantity)) {
					if (payload.children[index].quantity !== -1) {
						payload.children[index].quantity += 10;
					}
				} else {
					payload.children[index].quantity = 10;
				}
			}
			this.treeviewdata = payload;
		},
		DECREASE_LOT_10(state, payload) {
			for (let index = 0; index < payload.children.length; index++) {
				if (payload.children[index].quantity >= 10) {
					payload.children[index].quantity += -10;
				}
			}
			this.treeviewdata = payload;
		},
	},
	actions: {
		async getDevis({ commit }, etablissement) {
			await OrderService.getDevis(etablissement).then((data) => {
				commit("SET_DEVIS", data);
			});
		},
		async AddAdditionalsBooks({ commit }, payload) {
			commit("ADD_ADDITIONAL_BOOK", payload);
		},
		async DeleteAdditionalsBooks({ commit }, payload) {
			commit("DELETE_ADDITIONAL_BOOK", payload);
		},
		increase({ commit }, lot) {
			commit("INCREASE_LOTS", lot);
		},
		decrease({ commit }, lot) {
			commit("DECREASE_LOT", lot);
		},
		increaseBy10({ commit }, lot) {
			commit("INCREASE_LOTS_10", lot);
		},
		decreaseBy10({ commit }, lot) {
			commit("DECREASE_LOT_10", lot);
		},
	},
};

export default order;
