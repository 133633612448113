import { render, staticRenderFns } from "./NextButton.vue?vue&type=template&id=2411e3e5&scoped=true&"
import script from "./NextButton.vue?vue&type=script&lang=js&"
export * from "./NextButton.vue?vue&type=script&lang=js&"
import style0 from "./NextButton.vue?vue&type=style&index=0&id=2411e3e5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2411e3e5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VScrollXTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VScrollXTransition,VSpacer})
