import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import user from "./user";
import order from "./order";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth,
		user,
		order,
	},
	state: { snackbar: {} },
	mutations: {
		SET_SNACKBAR(state, snackbar) {
			state.snackbar = snackbar;
		},
	},
	actions: {
		setSnackbar({ commit }, snackbar) {
			snackbar.showing = true;
			snackbar.color = snackbar.color || "success";
			commit("SET_SNACKBAR", snackbar);
		},
	},
});
