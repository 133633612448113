<template>
  <v-card :height="$vuetify.breakpoint.mdAndUp ? `100%` : `100vmax`" class="align" >
    <v-img class="bg-image"></v-img>
    <div class="bg-content">
      <v-row class="d-flex justify-center pt-10">
        <v-col
          class="ma-0 pa-0"
          cols="10"
          md="6"
        >
          <v-card
            color="black"
            height="100%"
            class="d-flex justify-center align-center  rounded-lg"
            elevation="0"
            :class="{
              'rounded-b-0': !this.$vuetify.breakpoint.mdAndUp,
              'rounded-r-0': this.$vuetify.breakpoint.mdAndUp,
            }"
            min-width="300px"
            v-if="
              this.$vuetify.breakpoint.mdAndUp || (!forgetPass && !forgetID)
            "
          >
            <v-img
              class="imageForm"
              height="100%"
              position="30% 15%"
              src="~@/assets/loginBanner-min.jpg"
              elevation="0"
            >
            </v-img>
            <h1 class="
                text-h4 text-sm-h3 text-md-h2
                justify-center
                white--text
                textImage
                text-center
                text-uppercase
              ">
              bienvenue sur votre espace numérique ARBS
            </h1>
          </v-card>
        </v-col>

        <v-col
          class="ma-0 pa-0 mx-xs-auto"
          cols="10"
          md="6"
        >
          <v-card
            color="blackgroundColor"
            class="d-flex justify-center align-center ma-0 rounded-lg"
            elevation="1"
            :class="{
              'rounded-t-0': !this.$vuetify.breakpoint.mdAndUp,
              'rounded-l-0': this.$vuetify.breakpoint.mdAndUp,
            }"
            min-width="300px"
            :height="$vuetify.breakpoint.mdAndUp ? `100%` : ``"
          >
            <ForgetPassword
              @forget-ID="setForgetID"
              @forgot-password-cancel="setForgetPass"
              @send-email="setResetEmailSend"
              v-if="forgetPass"
            />
            <ForgetID
              @forget-ID-cancel="setForgetID"
              @send-emailID="sendEmailID"
              v-if="forgetID"
            />
            <Login
              @forgot-password="setForgetPass"
              @error-login="setErrorLogin"
              v-if="!forgetPass && !forgetID"
            />
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import Login from "/src/components/LoginVueComponents/Login.vue";
import ForgetPassword from "/src/components/LoginVueComponents/ForgetPassword.vue";
import ForgetID from "/src/components/LoginVueComponents/ForgetID";
export default {
  name: "Home",
  data: () => ({
    forgetPass: false,
    forgetID: false,
    resetEmailSend: false,
    errorLogin: false,
  }),
  components: {
    Login,
    ForgetPassword,
    ForgetID,
  },
  methods: {
    setForgetPass() {
      this.forgetPass = !this.forgetPass;
    },
    setForgetID() {
      this.forgetID = !this.forgetID;
      this.forgetPass = !this.forgetPass;
    },
    setResetEmailSend() {
      this.forgetPass = false;
      this.$store.dispatch("setSnackbar", {
        color: "success",
        text: " Email envoyé !",
      });
    },
    sendEmailID() {
      this.forgetID = false;
      this.forgetPass = false;
    },
    setErrorLogin(msg) {
      this.$store.dispatch("setSnackbar", {
        color: "error",
        text: msg ? msg : "Mot de passe ou identifiant incorrect",
      });
    },
  },
};
</script>
  
<style lang="scss">
@import "@/assets/scss/colors.scss";

.Login-title {
  font-family: "dincondensed",sans-serif;
  font-size: 22px;
  color: $primary;
}
</style>
<style scoped>
.bg-image {
  /* The image used */
  background-image: url("~@/assets/loginBanner-min.jpg");

  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}
.bg-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-content: center;
}
.imageForm {
  opacity: 0.6;
  position: absolute;
  background-position: top !important;
}
.textImage {
  position: relative;
  font-family: "bebasneue",sans-serif !important;
}
</style>

