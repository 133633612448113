import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/LoginVue.vue";
import Reset from "../views/ResetPasswordVue.vue";
import multiguard from "vue-router-multiguard";
import checkToken from "../middlewares/CheckTokenBeforeAccess.js";
import checkTokenBeforeLogin from "../middlewares/CheckTokenBeforeLogin.js";
import checkRole from "../middlewares/CheckRoleBeforeAccess";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Login",
		meta: { title: "Connexion ARBS" },
		component: Login,
		beforeEnter: checkTokenBeforeLogin,
	},
	{
		path: "/reset",
		name: "ResetPassword",
		meta: { title: "Réinitialiser le mot de passe" },
		component: Reset,
	},
	{
		path: "/dashboard",
		meta: { title: "Mes manuels" },
		name: "Dashboard",
		component: () => import("../views/DashboardVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsNotGestionnaire]),
	},
	{
		path: "/clean",
		meta: { title: "Nettoyage des manuels" },
		name: "Clean",
		component: () => import("../views/CleanVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsNotGestionnaire]),
	},
	{
		path: "/myAccount",
		name: "MyAccount",
		meta: { title: "Mon Compte" },

		component: () => import("../views/MyAccountVue.vue"),
		beforeEnter: checkToken,
	},
	{
		path: "/mydocuments",
		name: "MyDocuments",
		meta: { title: "Mes documents" },

		component: () => import("../views/MyDocumentsVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsNotGestionnaire]),
	},
	{
		path: "/admin/users",
		name: "DashboardAdmin",
		meta: { title: "Utilisateurs" },
		component: () => import("../views/Admin.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaireOrEtablissement]),
	},
	{
		path: "/admin/users/clean",
		name: "UserClean",
		meta: { title: "Utilisateurs" },
		component: () => import("../views/UserClean.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaireOrEtablissement]),
	},
	{
		path: "/admin/users/affectations",
		name: "AffectionOuvrages",
		meta: { title: "Affectations" },
		component: () => import("../views/AffectationVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaire]),
	},
	{
		path: "/admin/users/exports",
		name: "ExportsUtilisateurs",
		meta: { title: "Exports" },
		component: () => import("../views/ExportVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaireOrEtablissement]),
	},
	{
		path: "/admin/users/desaffectations",
		name: "DesaffectionOuvrages",
		meta: { title: "Désaffection" },
		component: () => import("../views/DesaffectationVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaire]),
	},
	{
		path: "/admin/users/suppressions",
		name: "SuppressionArchivageCompte",
		meta: { title: "Suppression compte" },
		component: () => import("../views/SuppressionVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaire]),
	},
	{
		path: "/admin/users/:id",
		name: "UserId",
		meta: { title: "Utilisateur" },
		component: () => import("../views/UserIdVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaireOrEtablissement]),
	},
	{
		path: "/admin/users/:id/manuels",
		name: "UserLibrary",
		meta: { title: "Utilisateur" },
		component: () => import("../views/UserLibraryVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaireOrEtablissement]),
	},
	{
		path: "/admin/etablissement",
		name: "Etablissement",
		meta: { title: "Etablissements" },
		component: () => import("../views/EtablissementVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaireOrEtablissement]),
	},
	{
		path: "/admin/etablissement/:id/mapping",
		name: "Mapping",
		meta: { title: "Mapping des résultats" },
		component: () => import("../views/EcoleDirectImportPreMapping.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaireOrEtablissement]),
	},
	{
		path: "/admin/etablissement/:id",
		name: "EtablissementENTEdition",
		meta: { title: "Paramètres de l'établissement" },
		component: () => import("../views/EtablissementId.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaire]),
	},
	{
		path: "/admin/import",
		name: "Import",
		meta: { title: "Importation de compte" },
		component: () => import("../views/ImportVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaireOrEtablissement]),
	},
	{
		path: "/admin/historique",
		name: "Historique",
		meta: { title: "Historique" },
		component: () => import("../views/HistoryVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaire]),
	},
	{
		path: "/admin/etablissement/statistique/:id",
		name: "EtabStatistique",
		meta: { title: "Historique" },
		component: () => import("../views/EtabStatistiqueVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaireOrEtablissement]),
	},
	{
		path: "/admin/statistique",
		name: "Statistique",
		meta: { title: "Statistique" },
		component: () => import("../views/StatistiqueVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaire]),
	},
	{
		path: "/admin/user/statistique/:id",
		name: "UserStatistique",
		meta: { title: "Statistique élèves" },
		component: () => import("../views/UserStatistiqueVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaireOrEtablissement]),
	},
	{
		path: "/admin/order/:id",
		name: "Order",
		meta: { title: "Commandes" },
		component: () => import("../views/OrderVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaire]),
	},
	{
		path: "/admin/order/:id/summary",
		name: "OrderSummary",
		meta: { title: "Commandes en detail" },
		component: () => import("../views/OrderSummaryVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaire]),
	},
	{
		path: "/admin/stock",
		name: "Stock",
		meta: { title: "Stock" },
		component: () => import("../views/StockVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaireOrEtablissement]),
	},
	{
		path: "/admin/params",
		name: "Params",
		meta: { title: "Paramétrage Editeur" },
		component: () => import("../views/ParamsVue.vue"),
		beforeEnter: multiguard([checkToken, checkRole.checkRoleIsGestionnaire]),
	},
	{ path: "*", redirect: "/admin/users" },
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

const DEFAULT_TITLE = "ARBS portail numérique";
router.afterEach((to) => {
	Vue.nextTick(() => {
		document.title = to.meta.title || DEFAULT_TITLE;
	});
});

export default router;
