import http from "/http-common"; ///axios
class OrderService {
	getDevis(id) {
		return http.post(`/gestionnaire/rechercherDevisEtablissement/${id}`);
	}
	sendOrder(order, idEtablissement) {
		return http.post(`/editeur/commande/${idEtablissement}`, order);
	}
	retrySendOrder(order, idEtablissement) {
		return http.put(`/editeur/commande/${idEtablissement}`, { ...order[0] });
	}
}
export default new OrderService();
