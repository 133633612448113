import store from '../store'
import AuthenticationService from "/src/services/AuthenticationService";

//s'execute à chaque entrée de page

export default function checkToken(to, from, next) {

    store.dispatch("auth/setTokenInfoFromLocalStorage");
    const isAuthenticated = store.getters["auth/isAuthenticated"];
    if (!isAuthenticated) {
        store.dispatch("setSnackbar", {
            color: "error",
            text: "Vous devez etre connecté pour accéder à cette page.",
          });
        next({ name: "Login" });
    } else {
        let date_token = store.state.auth.TokenInfo.date_token;
        let expires_in = store.state.auth.TokenInfo.expires_in
        let refresh_expires_in = store.state.auth.TokenInfo.refresh_expires_in;
        let date_now = new Date();
        let date_token_after = new Date(date_token);

        date_token_after.setSeconds(date_token_after.getSeconds() + expires_in);

        if (date_token_after < date_now) {
            date_token_after.setSeconds(date_token_after.getSeconds() + refresh_expires_in);
            if (date_token_after > date_now) {
                AuthenticationService.refresshToken(store.state.auth.TokenInfo.refresh_token)
                    .then((response) => {
                        store.dispatch("auth/updateAuthorisations", response.data.keycloakRefreshTokenResponse);
                    })
                next();
            }
            else {
                store.commit("auth/CLEAR_ACCESS_TOKEN");
                next({ name: "Login" });
            }
        } else {
            next();
        }
    }

}
