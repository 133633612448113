<template>
  <v-card
    class="mx-auto my-12 pa-4"
    height="100%"
    max-height="370px"
    max-width="500px"
  >
    <h1 class="text-h4 text-sm-h3 text-md-h3 primary--text py-4 font">
      Mot de passe oublié
    </h1>
    <v-form
      ref="ResetPasswordForm"
      v-model="valid"
    >
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            v-model="password"
            :rules="pwRules"
            label="Nouveau mot de passe"
            type="password"
            required
            dense
            filled
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            v-model="passwordConfirmation"
            :rules="[
              password === passwordConfirmation ||
                'Le mot de passe doit correspondre',
            ]"
            label="Confirmation du mot de passe"
            type="password"
            required
            dense
            filled
          />
        </v-col>
      </v-row>
      <div class="d-flex mt-5">
        <v-spacer></v-spacer>
        <v-btn
          name="Bouton envoyer"
          large
          tile
          depressed
          color="primary"
          @click="resetPW"
          :loading="loading"
          :disabled="!valid"
        >
          Envoyer
          <v-icon right>mdi-chevron-right-circle-outline</v-icon>
        </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import ForgotPWService from "../../services/ForgotPWService";

export default {
  name: "FormRestPassword",
  data: () => ({
    ERROR_MESSAGE: `Mot de passe invalide :
veuillez utiliser au moins 8 caractères dont une majuscule, une minuscule, un 
chiffre et un caractère spécial.`,
    valid: false,
    password: "",
    passwordConfirmation: "",
    loading: false,
  }),
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.passwordConfirmation ||
        "Les mots de passe doivent être identiques"; //"Password must match";
    },
    pwRules() {
      return [
        (v) => !!v || "Mot de passe requis",
        (v) => (v && v.length >= 8 && /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[()=+{}_#?!@$%^&*-]).{8,}$/.test(v)) || this.ERROR_MESSAGE, // Password must have 8+ characters
      ];
    },
  },
  methods: {
    resetPW() {
      if (this.valid) {
        this.loading = true;
        ForgotPWService.newPW(this.password, this.$route.query.key)
          .then(() => {
            this.loading = false;
            this.$store.dispatch("setSnackbar", {
              color: "success",
              text: "Votre mot de passe a été mis à jour",
            });
            this.$router.push({
              path: `/`,
            }).catch((error) => {
              if (error.name !== "NavigationDuplicated") {
                throw error;
              }
            });
          })
          .catch(() => {
            this.loading = false;
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Une erreur est survenue, veuillez réessayer dans un moment.",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.font {
  font-family: "dincondensed",sans-serif !important;
}
</style>
