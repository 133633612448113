<template>
  <v-app class="GeneralApplication">
    <Header />
    <router-view :key="$route.fullPath"/>
    <Toast />
  </v-app>
</template>

<script lang="ts">
import Header from "./components/Header.vue";
import Toast from "./components/Utility/Toast.vue";

export default {
  components: {
    Header,
    Toast,
  },
};
</script>

<style lang="scss">
@import "./assets/scss/fonts.scss";
.GeneralApplication {
  min-width: 330px;
}
</style>
