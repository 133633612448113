import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import fr from 'vuetify/lib/locale/fr'
export default new Vuetify({
	lang: {
		locales: { fr },
		current: "fr",
	},
	theme: {
		themes: {
			light: {
				primary: "#034155",
				lightprimary: "#d5dde1",
				secondary: "#fb4d61",
				lightsecondary: "#f7cfd3",
				error: "#fb4d61",
				success: "#67afb1",
				warning: "#FFC107",
				orange: "#FF8657",
				gray: "#909090",
				green: "#67afb1",
				red: "#FF5c4E",
				lightgray: "#BDBDBD",
				white: "#FFFFFF",
				backgroundColor: "#f5f5f5",
				purple: "#845f92",
			},
		},
	},
});
