import http from "/http-common"; ///axios
class UserService {
	getInfo() {
		return http.get(`/utilisateur`);
	}
	putAccount(data) {
		const newPWD = {
			"newPassword": data
		}
		return http.put(`/utilisateur/edit-account`, newPWD);
	}
}
export default new UserService();
