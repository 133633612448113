import UserService from "../services/UserService";

const user = {
	namespaced: true,
	state: {
		identifiant: null,
		nom: null,
		prenom: null,
		emailContact: null,
		codeAdherent: null,
		role: null,
		soutienBordas: null,
		etablissements: null,
		classe: null,
	},
	mutations: {
		SET_USER(state, payload) {
			state.identifiant = payload.identifiant;
			state.nom = payload.nom;
			state.prenom = payload.prenom;
			state.emailContact = payload.emailContact;
			state.codeAdherent = payload.codeAdherent;
			state.role = payload.role;
			state.soutienBordas= payload.soutienBordas;
			state.etablissements = payload.etablissements;
			state.classe= payload.classe;
		},
		RESET_USER(state) {
			state.identifiant = null;
			state.nom = null;
			state.prenom = null;
			state.emailContact = null;
			state.codeAdherent = null;
			state.role = null;
			state.soutienBordas= null;
			state.etablissement= null;
			state.classe= null;
		},
	},
	actions: {
		async updateUser({ commit }) {
			await UserService.getInfo().then((user) => {
				commit("SET_USER", user.data);
			});
		},
		async resetUser({ commit }) {
			commit("RESET_USER");
		},
	},
};

export default user;
