<template>
  <v-col
      cols="12"
      class="pa-0 ma-0"
  >
    <v-card
        class="pa-8"
        flat
    >
      <v-row>
        <v-col cols="12">
          <p
              class="font text-h5 font-weight-dark primary--text font mb-0"
          >ID oublié</p>
          <p class=" font text-h6 font-weight-bold secondary--text">Veuillez indiquer l'email parent de votre compte
            ARBS</p>
          <p class="  text-body-1 info--text">Vous recevrez par email tous les identifiants associés à votre compte
            parent.</p>
        </v-col>
      </v-row>
      <v-form
          ref="ForgetID"
          v-model="valid"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="emailUser"
                label="Email parent*"
                type="email"
                :rules="iDRules"
                required
                dense
                filled
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="d-flex flex-column-reverse mt-5 flex-sm-row ">
              <v-btn
                  name="c annuler"
                  @click="emitCancel"
                  large
                  tile
                  depressed
                  color="secondary"
                  class="mt-sm-0 mt-6"
              >
                <v-icon left>mdi-chevron-left-circle-outline</v-icon>
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  name="bouton envoyer"
                  large
                  tile
                  depressed
                  color="primary"
                  @click="sendMailRecapID"
                  :disabled="!valid"
                  class="mt-0"
                  :loading="loading"
              >
                Envoyer
                <v-icon right>mdi-chevron-right-circle-outline</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

      </v-form>
    </v-card>
  </v-col>
</template>

<script>
import ForgotIDService from "./../../services/ForgotIDService";

export default {
  data: () => ({
    valid: false,
    emailUser: "",
    ERROR_MESSAGE: `Email invalide`,
    loading: false,
  }),
  computed: {
    iDRules() {
      return [
        (v) =>
            /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v) ||
            this.ERROR_MESSAGE, // Must have one uppercase character
      ];
    },
  },
  methods: {
    emitCancel() {
      this.$emit("forget-ID-cancel");
    },
    sendMailRecapID() {
      if (this.valid) {
        this.loading = true;
        ForgotIDService.postSendEmail(this.emailUser)
            .then(() => {
              this.loading = false;
              this.$store.dispatch("setSnackbar", {
                color: "success",
                text: `Email envoyé à ${this.emailUser}`,
              });
              this.$emit("send-emailID");
            })
            .catch(() => {
              this.loading = false;
              this.$store.dispatch("setSnackbar", {
                color: "error",
                text: "Une erreur est survenue, veuillez réessayer dans un moment.",
              });
            });
      }
    },
  },
};
</script>


<style scoped>
.font {
  font-family: "dincondensed", sans-serif !important;
}
</style>
