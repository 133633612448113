<template>
  <v-btn
      :name="text"
      height="78px"
      elevation="0"
      :color="color"
      fab
      tile
      :disabled="clicked"
      @click="locked ? clicked = true : ''"
      width="100%"
      style="justify-content: left"
  >
    <v-icon x-large :color="iconColor" right class="mx-8"> {{ icon }}</v-icon>
    <div
        class="NavBouton font-weight-bold textColor"
        :class="{ 'secondary--text': IsActiveNamePage }"
    >
      {{ text }}
    </div>
  </v-btn>
</template>

<script>
export default {
  name: "ButtonSidebar",
  props: ["color", "text", "icon", "iconColor", "IsActiveNamePage", 'locked'],
  data: () => ({
    clicked: false,
  }),
  watch: {
    IsActiveNamePage: {
      handler() {
        this.clicked = false;
      },
    },
  },
};
</script>
<style scoped>
</style>
