import axiosInstance from "../../http-common";
import AuthenticationService from "/src/services/AuthenticationService";

//s'execute avant chaque requete à l'api

const setup = (store, router) => {
    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (originalConfig.url !== "/identification" && originalConfig.url !== "/identification/qrcode" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const rs = await AuthenticationService.refresshToken(store.state.auth.TokenInfo.refresh_token);
                          store.dispatch('auth/updateAuthorisations', rs.data.keycloakRefreshTokenResponse);
                          originalConfig.headers["Authorization"] = "Bearer " + rs.data.keycloakRefreshTokenResponse.access_token;
                          return axiosInstance(originalConfig);                          
                    }
                    catch (_error) {
                        store.dispatch("setSnackbar", {
                            color: "error",
                            text: "Votre session a expirée",
                          });
                        store.commit("auth/CLEAR_ACCESS_TOKEN");
                        router.push({ name: "Login" }).catch((error) => {
                            if (error.name !== "NavigationDuplicated") {
                                throw error;
                            }
                        });
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(err);
        }
    );
};
export default setup;
