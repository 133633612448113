import http from "/http-common"; ///axios
class ForgotPWService {
	postSendEmail(userID) {
		return http.post(`/identification/reset-password`, {
			username: userID,
		});
	}
	newPW(newPassword, resetToken) {
		return http.post(`/identification/change-password`, {
			newPassword: newPassword,
			resetToken: resetToken,
		});
	}
}
export default new ForgotPWService();
