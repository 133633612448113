import http from "../../http-common";

const auth = {
	namespaced: true,
	state: {
		TokenInfo: {
			access_token: "",
			refresh_token: "",
			expires_in: 0,
			refresh_expires_in: 0,
			date_token: 0,
		},
	},

	getters: {
		isAuthenticated(state) {
			return !!state.TokenInfo.access_token;
		},
	},

	mutations: {
		SET_AUTHAURISATIONS(state, payload) {
			if (!payload.date_token) {
				state.TokenInfo.date_token = new Date();
			} else {
				state.TokenInfo.date_token = payload.date_token;
			}
			state.TokenInfo.expires_in = payload.expires_in;
			state.TokenInfo.refresh_expires_in = payload.refresh_expires_in;
			state.TokenInfo.access_token = payload.access_token;
			state.TokenInfo.refresh_token = payload.refresh_token;
			localStorage.setItem("TokenInfo", JSON.stringify(state.TokenInfo));
			http.defaults.headers.Authorization = "Bearer " + payload.access_token; //axios
		},
    
		CLEAR_ACCESS_TOKEN(state) {
			state.TokenInfo.access_token = "";
			state.TokenInfo.refresh_token = "";
			localStorage.removeItem("TokenInfo");
      delete http.defaults.headers.Authorization;
		},
	},
	actions: {
		updateAuthorisations({ commit }, payload) {
			commit("SET_AUTHAURISATIONS", payload);
		},
		clearToken({ commit }) {
			commit("CLEAR_ACCESS_TOKEN");
		},

		setTokenInfoFromLocalStorage({ commit }) {
			if (localStorage.getItem("TokenInfo")) {
				const LocalStorageTokenInfo = JSON.parse(
					localStorage.getItem("TokenInfo")
				);
				commit("SET_AUTHAURISATIONS", LocalStorageTokenInfo);
			}
		},
	},
};

export default auth;
