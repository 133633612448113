<template>
  <v-col
      cols="12"
      class="pa-0 ma-0"
  >
    <v-card
        class="pa-8"
        flat
    >
      <v-row>
        <v-col cols="12">
          <p
              class="font text-h5 font-weight-dark primary--text font mb-0"
          >Mot de passe oublié</p>
          <p class=" font text-h6 font-weight-bold secondary--text">Veuillez indiquer votre identifiant élève</p>
          <p class="  text-body-1 info--text">Vous recevrez à l'adresse email associée à votre compte parent un email
            contenant un lien de réinitialisation de votre mot de passe.</p>
        </v-col>
      </v-row>
      <v-form
          ref="ForgetPassword"
          v-model="valid"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="idUser"
                label="Identifiant *"
                type="text"
                :rules="iDRules"
                required
                dense
                filled
            >
            </v-text-field>
          </v-col>
        </v-row>
        <div
            class="d-flex"
            align="center"
        >
          <a
              @click="emitForgetID"
              class="Login-link secondary--text"
          >Identifiant perdu ? </a>
        </div>
        <v-row>
          <v-col cols="12">
            <div class="d-flex flex-column-reverse mt-5 flex-sm-row ">
              <v-btn
                  name="Bouton annuler"
                  @click="emitCancel"
                  large
                  tile
                  depressed
                  color="secondary"
                  class="mt-sm-0 mt-6"
              >
                <v-icon left>mdi-chevron-left-circle-outline</v-icon>
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  name="Bouton envoyer"
                  large
                  tile
                  depressed
                  color="primary"
                  @click="sendResetMail"
                  :disabled="!valid"
                  class="mt-0"
              >
                Envoyer
                <v-icon right>mdi-chevron-right-circle-outline</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-col>
</template>

<script>
import ForgotPWService from "./../../services/ForgotPWService";

export default {
  data: () => ({
    valid: false,
    idUser: "",
    iDRules: [
      (v) => !!v || "Identifiant obligatoire",
      (v) => (v && v.length >= 5) || "identifiant trop court", // Password must have 5+ characters
    ],
  }),
  methods: {
    emitForgetID() {
      this.$emit("forget-ID");
    },
    emitCancel() {
      this.$emit("forgot-password-cancel");
    },
    sendResetMail() {
      //Valide Formulaire
      this.$refs.ForgetPassword.validate();
      if (this.valid) {
        ForgotPWService.postSendEmail(this.idUser);
        this.$emit("send-email");
      }
    },
  },
};
</script>


<style scoped>
.font {
  font-family: "dincondensed", sans-serif !important;
}

.Login-link {
  display: block;
  font-family: "dincondensed", sans-serif;
  color: #fb4d61;
  text-decoration: none;
  height: 30px;
}

a {
  position: relative;
  z-index: 50;
}

a:before {
  position: absolute;
  content: "";
  top: -10px;
  right: -10px;
  left: -10px;
  bottom: -10px;
  z-index: 40;
}
</style>
