import http from "/http-common";
import axios from "axios";

class AuthenticationService {
	postLogin(data) {
		return http.post("/identification", data);
	}
	postLoginQrCode(data) {
		return http.post("/identification/qrcode", data);
	}
	postLoginTicketCas(cas) {
		return http.post("/identification/ticket-cas", cas);
	}
	postLogout() {
		return http.delete("/identification/logout");
	}
	refresshToken(data) {
		const refreshData = {
			refresh_token: data,
		};
		return axios.post(
			process.env.VUE_APP_URL + "/identification/refresh-token",
			refreshData
		);
	}
}
export default new AuthenticationService();
