<template>
  <v-btn
      :name="text"
      class="AccessButton px-4"
      height="44px"
      :width=width
      max-width="100%"
      elevation="0"
      :color=color
      fab
      @click="emitClicked"
      :loading=loading
      :outlined="isHovered"
      @mouseover="hovered()"
      @mouseleave="notHovered()"
      :disabled=disable
  >
    {{ text }}
    <v-spacer/>
    <v-scroll-x-transition>
      <v-icon
          class="ml-5"
          :class="boutonMove"
      > {{ icon }}
      </v-icon>
    </v-scroll-x-transition>
  </v-btn>
</template>

<script>
export default {
  name: "NextButton",
  props: {
    width: String,
    color: String,
    text: String,
    loading: {default: false},
    disable: {default: false},
    icon: {default: "mdi-chevron-right-circle-outline"},
  },
  data() {
    return {
      isHovered: false,
      boutonMove: "pr-4",
    };
  },
  methods: {
    hovered() {
      this.isHovered = true;
      this.boutonMove = "pr-1";
    },
    notHovered() {
      this.isHovered = false;
      this.boutonMove = "pr-4";
    },
    emitClicked() {
      this.$emit("clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
.AccessButton {
  border-radius: 4px;
  padding: 19.55px;
  font-weight: 800;
  letter-spacing: 0;
  color: white;
}
</style>
