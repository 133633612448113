<template>
  <v-card
    class="pb-6 backgroundColor"
    height="100%"
  >
    <v-img class="bg-image"></v-img>
    <div class="bg-content">
      <FormResetPassword />
    </div>
  </v-card>
</template>

<script>
import FormResetPassword from "../components/ResetPassewordVueComponent/FormResetPassword.vue";

export default {
  components: { FormResetPassword },
};
</script>

<style scoped>
.bg-image {
  /* The image used */
  background-image: url("~@/assets/loginBanner-min.jpg");

  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}
.bg-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-content: center;
}
</style>