<template>
  <div>
    <v-app-bar
        color="white"
        :height="headerHeight"
        elevation="0"
        class="nav"
    >
      <div class="hidden-lg-and-up">
        <v-app-bar-nav-icon
            aria-label="Menu latérale"
            name="Menu latérale"
            @click="drawer = true"
        ></v-app-bar-nav-icon>
      </div>
      <!--logo ARBS vers login-->
      <router-link
          name="Logo ARBS"
          to="/"
          aria-label="Logo ARBS"
      >

        <v-img
            lazy-src="@/assets/img/logo.png"
            contain
            :height="headerHeight"
            max-width="200px"
            src="@/assets/img/logo.png"
            v-if="this.$vuetify.breakpoint.mdAndUp"
        />
        <v-img
            contain
            :height="headerHeight"
            max-width="80px"
            src="@/assets/img/logo-2.png"
            v-else
        />
      </router-link>
      <v-spacer/>
      <!--Entete Bienvenue+nom-->
      <div
          class="d-flex flex-column flex-sm-row"
          v-if="this.$route.name !== 'Login' && this.$route.name !== 'ResetPassword'"
      >
        <p
            class="  mr-sm-2 text-h5 primary--text ma-auto"
            v-if="this.$vuetify.breakpoint.mdAndUp && user.role !== 'GESTIONNAIRE_ARBS' &&  user.role !== 'GESTIONNAIRE_ETABLISSEMENT' && user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'"
        >
          BIENVENUE
        </p>
        <p class="ma-0 mr-sm-2 text-h5 secondary--text ma-auto">
          {{ user.prenom }}
        </p>
        <p class="ma-0 text-h5 primary--text ma-auto">
          {{ user.nom }}
        </p>
      </div>
      <v-spacer/>
      <div class="hidden-md-and-down">
        <ButtonNavbar
            :height="headerHeight"
            color="primary"
            colorBackground="white"
            text="Import"
            :locked=true
            icon="mdi-database-import-outline"
            iconColor="primary"
            :isActiveNamePage="isActiveNamePage('Import')"
            @click.native="$router.push({ path: `/admin/import` }).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
        throw error;
        }
        });"
            v-if="
            this.$route.name !== 'Login' &&
            this.$route.name !== 'ResetPassword' &&
            (this.$store.state.user.role === 'GESTIONNAIRE_ARBS' ||
            this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')
          "
        />
        <ButtonNavbar
            :height="headerHeight"
            color="primary"
            colorBackground="white"
            text="Editeur"
            :locked=true
            icon="mdi-progress-wrench"
            iconColor="primary"
            :isActiveNamePage="isActiveNamePage('Params')"
            @click.native="$router.push({ path: `/admin/params` }).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
        throw error;
        }
        });"
            v-if="
            this.$route.name !== 'Login' &&
            this.$route.name !== 'ResetPassword' &&
            this.$store.state.user.role === 'GESTIONNAIRE_ARBS'
          "
        />
        <ButtonNavbar
            :height="headerHeight"
            color="primary"
            colorBackground="white"
            text="Stock"
            :locked=true
            icon="mdi-dolly"
            iconColor="primary"
            :isActiveNamePage="isActiveNamePage('Stock')"
            @click.native="$router.push({ path: `/admin/stock` }).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
        throw error;
        }
        });"
            v-if="
            this.$route.name !== 'Login' &&
            this.$route.name !== 'ResetPassword' &&
          (this.$store.state.user.role === 'GESTIONNAIRE_ARBS' ||
          this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')
          "
        />
        <ButtonNavbar
            :height="headerHeight"
            color="primary"
            colorBackground="white"
            text="Statistiques"
            :locked=true
            icon="mdi-chart-line"
            iconColor="primary"
            :isActiveNamePage="isActiveNamePage('Statistique')"
            @click.native="$router.push({ path: `/admin/statistique` }).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
        throw error;
        }
        });"
            v-if="
            this.$route.name !== 'Login' &&
            this.$route.name !== 'ResetPassword' &&
            this.$store.state.user.role === 'GESTIONNAIRE_ARBS'
          "
        />
        <ButtonNavbar
            :height="headerHeight"
            color="primary"
            colorBackground="white"
            text="Historique"
            icon="mdi-history"
            :locked=true
            iconColor="primary"
            :isActiveNamePage="isActiveNamePage('Historique')"
            @click.native="$router.push({ path: `/admin/historique` }).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
        throw error;
        }
        });"
            v-if="
            this.$route.name !== 'Login' &&
            this.$route.name !== 'ResetPassword' &&
            this.$store.state.user.role === 'GESTIONNAIRE_ARBS'
          "
        />
        <ButtonNavbar
            :height="headerHeight"
            color="primary"
            colorBackground="white"
            text="Etablissements"
            icon="mdi-domain"
            :locked=true
            iconColor="primary"
            :isActiveNamePage="isActiveNamePage('Etablissement')"
            @click.native="goToEtablissement()"
            v-if="
            this.$route.name !== 'Login' &&
            this.$route.name !== 'ResetPassword' &&
            (this.$store.state.user.role === 'GESTIONNAIRE_ARBS' || this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT' || this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')
          "
        />

        <ButtonNavbar
            :height="headerHeight"
            color="primary"
            colorBackground="white"
            text="Utilisateurs"
            icon="mdi-shield-account"
            iconColor="primary"
            :locked=true
            :isActiveNamePage="isActiveNamePage('DashboardAdmin')"
            @click.native="goToAdmin()"
            v-if="
            this.$route.name !== 'Login' &&
            this.$route.name !== 'ResetPassword' &&
            (this.$store.state.user.role === 'GESTIONNAIRE_ARBS' || this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT' || this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')
          "
        />

        <ButtonNavbar
            :height="headerHeight"
            color="primary"
            colorBackground="white"
            text="Accès capeezi"
            icon="mdi-school"
            :locked=false
            iconColor="primary"
            @click.native="goToSoutien()"
            v-if="
            this.$route.name !== 'Login' &&
            this.$route.name !== 'ResetPassword' &&
            this.$store.state.user.soutienBordas
          "
        />
        <ButtonNavbar
            :height="headerHeight"
            color="primary"
            colorBackground="white"
            text="MON COMPTE"
            :locked=true
            icon="mdi-account"
            iconColor="primary"
            :isActiveNamePage="isActiveNamePage('MyAccount')"
            @click.native="goToMyAccount()"
            v-if="
            this.$route.name !== 'Login' &&
            this.$route.name !== 'ResetPassword'
          "
        />
        <ButtonNavbar
            :height="headerHeight"
            color="primary"
            colorBackground="white"
            text="Accueil"
            icon="mdi-home"
            :locked=true
            iconColor="primary"
            :isActiveNamePage="isActiveNamePage('Dashboard')"
            @click.native="goToMyDashboard()"
            v-if="
            this.$route.name !== 'Login' &&
            this.$route.name !== 'ResetPassword' &&
            this.$store.state.user.role !== 'GESTIONNAIRE_ARBS' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'
          "
        />
        <ButtonNavbar
            :height="headerHeight"
            color="primary"
            colorBackground="white"
            :locked=false
            text="Assistance"
            icon="mdi-comment-question"
            iconColor="primary"
            @click.native="goToHelp()"
            v-if="this.$store.state.user.role !== 'GESTIONNAIRE_ARBS' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'"
        />
        <!-- Bouton logout -->
        <v-hover
            v-slot="{ hover }"
            v-if="
            this.$route.name !== 'Login' && this.$route.name !== 'ResetPassword'
          "
        >
          <v-btn
              name="déconnexion"
              :height="headerHeight"
              elevation="0"
              color="secondary"
              tile
              width="150px"
              dark
              @click="logout()"
          >
            <div class="d-flex flex-column">
              <v-icon
                  large
                  color="white"
                  justify-center
              >mdi-logout
              </v-icon>
              <div
                  class="text-h8 HeaderBouton font-weight-bold ma-0"
                  :class="{ 'text-decoration-underline': hover }"
              >
                déconnexion
              </div>
            </div>
          </v-btn>
        </v-hover>
      </div>
      <!--/////////////////////////LEFT MENU//////////////////////////-->
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
    >
      <v-sheet height="50">
        <v-icon
            style="margin: 8px"
            @click="drawer = false"
        >mdi-close
        </v-icon>
      </v-sheet>
      <!--Bouton déconnexoin-->
      <Button
          color="secondary"
          text="Déconnexion"
          icon="mdi-logout"
          iconColor="white"
          @click.native="logout()"
          v-if="
          this.$route.name !== 'Login' && this.$route.name !== 'ResetPassword'
        "
      />
      <!--Bouton assistance-->
      <Button
          color="primary"
          text="Assistance"
          icon="mdi-comment-question"
          iconColor="white"
          :locked=false
          @click.native="goToHelp()"
          v-if="this.$store.state.user.role !== 'GESTIONNAIRE_ARBS' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT'  && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'"
      />
      <!--Bouton mon compte-->
      <Button
          :color="isActiveNamePage('MyAccount') ?  'backgroundColor': 'white'"
          text="MON COMPTE"
          icon="mdi-account"
          :locked=false
          :iconColor="isActiveNamePage('MyAccount') ?  'secondary' : 'primary'"
          :isActiveNamePage="isActiveNamePage('MyAccount')"
          @click.native="goToMyAccount()"
          v-if="
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' 
        "
      />
      <!--Bouton soutien-->
      <Button
          color="white"
          text="Accès capeezi"
          icon="mdi-school"
          iconColor="primary"
          @click.native="goToSoutien()"
          :locked=false
          v-if="
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          this.$store.state.user.soutienBordas
        "
      />
      <!--Bouton Acceuil-->
      <Button
          :color="isActiveNamePage('Dashboard') ?  'backgroundColor': 'white'"
          text="Accueil"
          icon="mdi-book-open-page-variant-outline"
          :iconColor="isActiveNamePage('Dashboard') ? 'secondary' : 'primary'"
          :isActiveNamePage="isActiveNamePage('Dashboard')"
          @click.native="goToMyDashboard()"
          :locked=false
          v-if="
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          this.$store.state.user.role !== 'GESTIONNAIRE_ARBS' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT'  && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'
        "
      />
      <Button
          :color="isActiveNamePage('Nettoyage des manuels') ?  'backgroundColor': 'white'"
          text="Nettoyage"
          icon="mdi-vacuum-outline"
          :iconColor="isActiveNamePage('Nettoyage des manuels') ? 'secondary' : 'primary'"
          :isActiveNamePage="isActiveNamePage('Nettoyage des manuels')"
          @click.native="goToMyCleanup()"
          :locked=false
          v-if="
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          this.$store.state.user.role !== 'GESTIONNAIRE_ARBS' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'
        "
      />
      <Button
          :color="isActiveNamePage('Mes documents') ?  'backgroundColor': 'white'"
          text="Mes documents"
          icon="mdi-file-document-multiple-outline"
          :iconColor="isActiveNamePage('Mes documents') ? 'secondary' : 'primary'"
          :isActiveNamePage="isActiveNamePage('Mes documents')"
          @click.native="goToMyDocuments()"
          :locked=false
          v-if="
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          this.$store.state.user.role !== 'GESTIONNAIRE_ARBS' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT' && this.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'  &&
          isMobileApp()
        "
      />
      <!--Bouton Utilisateurs-->
      <Button
          :color="isActiveNamePage('DashboardAdmin') ?  'backgroundColor': 'white'"
          text="Utilisateurs"
          icon="mdi-shield-account"
          :locked=false
          :iconColor="isActiveNamePage('DashboardAdmin') ? 'secondary' : 'primary'"
          :isActiveNamePage="isActiveNamePage('DashboardAdmin')"
          @click.native="goToAdmin()"
          v-if="
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          (this.$store.state.user.role === 'GESTIONNAIRE_ARBS' || this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT'|| this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')
        "
      />
      <!--Bouton etablissements-->
      <Button
          :color="isActiveNamePage('Etablissement') ?  'backgroundColor': 'white'"
          text="Etablissements"
          icon="mdi-domain"
          :locked=false
          :iconColor="isActiveNamePage('Etablissement') ? 'secondary' : 'primary'"
          :isActiveNamePage="isActiveNamePage('Etablissement')"
          @click.native="goToEtablissement()"
          v-if="
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          (this.$store.state.user.role === 'GESTIONNAIRE_ARBS' || this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT'|| this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')
        "
      />
      <!--Bouton statistiques-->
      <Button
          :locked=false
          :color="isActiveNamePage('Statistique') ?  'backgroundColor': 'white'"
          text="Statistiques"
          icon="mdi-chart-line"
          :iconColor="isActiveNamePage('Statistique') ? 'secondary' : 'primary'"
          :isActiveNamePage="isActiveNamePage('Statistique')"
          @click.native="goToStatistiques();"
          v-if="
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          this.$store.state.user.role === 'GESTIONNAIRE_ARBS'
        "
      />
      <!--Bouton historique-->
      <Button
          :color="isActiveNamePage('Historique') ?  'backgroundColor': 'white'"
          text="Historique"
          :locked=false
          icon="mdi-history"
          :iconColor="isActiveNamePage('Historique') ? 'secondary' : 'primary'"
          :isActiveNamePage="isActiveNamePage('Historique')"
          @click.native="goToHistorique();"
          v-if="
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          this.$store.state.user.role === 'GESTIONNAIRE_ARBS'
        "
      />
      <!--Bouton stock-->
      <Button
          :color="isActiveNamePage('Stock') ?  'backgroundColor': 'white'"
          text="Stock"
          icon="mdi-dolly"
          :locked=false
          :iconColor="isActiveNamePage('Stock') ? 'secondary' : 'primary'"
          :isActiveNamePage="isActiveNamePage('Stock')"
          @click.native="goToStock();"
          v-if="
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          (this.$store.state.user.role === 'GESTIONNAIRE_ARBS' ||
          this.$store.state.user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')
        "
      />
      <!--Bouton Editeur-->
      <Button
          :color="isActiveNamePage('Params') ?  'backgroundColor': 'white'"
          text="Editeur"
          icon="mdi-progress-wrench"
          :locked=false
          :iconColor="isActiveNamePage('Params') ? 'secondary' : 'primary'"
          :isActiveNamePage="isActiveNamePage('Params')"
          @click.native="$router.push({ path: `/admin/params` }).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
      throw error;
      }
      });"
          v-if="
          this.$route.name !== 'Login' &&
          this.$route.name !== 'ResetPassword' &&
          this.$store.state.user.role === 'GESTIONNAIRE_ARBS'
        "
      />
      <Button
          :color="isActiveNamePage('Import') ?  'backgroundColor': 'white'"
          text="Import"
          :locked=false
          icon="mdi-database-import-outline"
          :isActiveNamePage="isActiveNamePage('Import')"
          :iconColor="isActiveNamePage('Import') ? 'secondary' : 'primary'"
          @click.native="$router.push({ path: `/admin/import` }).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
        throw error;
        }
        });"
          v-if="
            this.$route.name !== 'Login' &&
            this.$route.name !== 'ResetPassword' &&
            this.$store.state.user.role === 'GESTIONNAIRE_ARBS'
          "
      />
    </v-navigation-drawer>
  </div>
</template>
<script>
import AuthenticationService from "/src/services/AuthenticationService";
import Button from "./Header/Sidebar/ButtonSidebar.vue";
import ButtonNavbar from "./Header/Navbar/ButtonNavbar.vue";

export default {
  data: () => ({
    drawer: false,
    group: null,
    assistanceURL: process.env.VUE_APP_ASSISTANCE_URL,
    headerHeight: "80px",
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  components: {
    Button,
    ButtonNavbar,
  },
  methods: {
    isMobileApp() {
      return /ARBSMobile/i.test(navigator.userAgent);
    },
    goToAdmin() {
      this.$router.push({name: "DashboardAdmin"}).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    goToEtablissement() {
      this.$router.push({name: "Etablissement"}).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    goToMyDocuments() {
      window.open(process.env.VUE_APP_BASE_URL + "mydocuments", "_self")
    },
    goToStatistiques() {
      this.$router.push({path: `/admin/statistique`}).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    goToStock() {
      this.$router.push({path: `/admin/stock`}).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    goToHistorique() {
      this.$router.push({path: `/admin/historique`}).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    goToMyAccount() {
      this.$router.push({name: "MyAccount"}).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    goToMyDashboard() {
      this.$router.push("/dashboard").catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    goToMyCleanup() {
      this.$router.push("/clean").catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    goToSoutien() {
      window.open("https://capeezy.directplateforme.com/", "_blank");
    },
    goToHelp() {
      window.open(this.assistanceURL, "_blank");
    },
    isActiveNamePage(name) {
      return this.$route.name === name;
    },
    async logout() {
      try {
        await AuthenticationService.postLogout();
        localStorage.removeItem("qrcode");
      } catch (error) {
        console.log("error:", error);
      }
      await this.$store.dispatch("auth/clearToken");
      await this.$store.dispatch("user/resetUser");
      await this.$router.push({name: "Login"}).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
  },
  mounted() {
    if (
        this.$route.name !== "Login" &&
        this.$route.name !== "ResetPassword" &&
        !this.$store.state.user.nom
    ) {
      this.$store.dispatch("user/updateUser");
    }
  },
};
</script>
<style lang="scss" scoped>
.LogoutBouton {
  font-family: "bebasneue", sans-serif;
}

.HeaderBouton {
  font-family: "bebasneue", sans-serif;
}

.NavBouton {
  font-family: "bebasneue", sans-serif;
  font-size: 22px !important;
}

.disable-events {
  pointer-events: none;

}

.nav {
  z-index: 5;
}
</style>
